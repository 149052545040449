* {
    box-sizing: border-box;
    font-family: "Inter", sans-serif;
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-thumb {
    background-color: #4521A0 !important;
    border-radius: 6px !important;
}

::-webkit-scrollbar-track {
    background: #222;
}

a {
    text-decoration: none;
}

.ql-snow .ql-picker {
    color: #fff !important;
}

.ql-snow .ql-stroke {
    stroke: #fff !important;
}

.ql-toolbar.ql-snow {
    border-radius: 0.5rem;
}

.ql-container.ql-snow {
    border: none !important;
}

.ql-snow .ql-fill,
.ql-snow .ql-stroke.ql-fill {
    fill: #fff !important;
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    background-color: #3D3D3D !important;
}

.scrollDiv {
    background: #7150D0;
    overflow-x: hidden;
}

.scrollText {
    font-family: "Inter", sans-serif;
    font-weight: 300;
    font-size: 1.5rem;
    font-weight: 400;
    animation: scroll 10s linear infinite;
}

.star {
    width: 30px;
    height: auto;
    margin: 0 0.5rem;
}

.scrollDiv:hover .scrollText {
    cursor: pointer;
    animation-play-state: paused;
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100%);
    }
}

.slick-slide.slick-active.slick-center.slick-current img {
    background: #B095FF;
    border-radius: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(1.2) !important;
    padding: 1rem;
}

.slick-initialized .slick-slide {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    transform: scale(0.8) !important;
}

@media screen and (max-width: 600px) {
    .scrollText {
        font-size: 0.6rem;
    }

    .star {
        width: 10px;
        height: auto;
        margin: 0 0.2rem;
    }

    .slick-initialized .slick-slide {
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        transform: scale(1) !important;
    }
}

.bannerImg {
    width: 80%;
    height: auto;
}

.ql-editor.ql-blank::before {
    color: #fff !important;
}


.not-found-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #1D1B29;
    font-family: 'Arial', sans-serif;
  }
  
  .not-found-content {
    text-align: center;
    padding: 40px;
    background: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  
  .not-found-title {
    font-size: 96px;
    color: #1D1B29;
    margin-bottom: 20px;
  }
  
  .not-found-subtitle {
    font-size: 32px;
    color: #343a40;
    margin-bottom: 20px;
  }
  
  .not-found-description {
    font-size: 16px;
    color: #6c757d;
    margin-bottom: 30px;
  }
  
  .not-found-link {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff;
    color: #ffffff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .not-found-link:hover {
    background-color: #0056b3;
  }
  